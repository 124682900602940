import React from 'react'
import { graphql } from 'gatsby'
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from 'gatsby-plugin-react-i18next'
import clsx from 'clsx'
import Layout from 'src/components/Layout'
import Seo from 'src/components/Seo'

const Index: React.FC = () => {
  const { t } = useTranslation()
  const { language } = useI18next()
  return (
    <Layout>
      <Seo title={t('home')} />
      <div className="md:min-h-screen flex items-center justify-center">
        <p
          className={clsx(
            'hidden md:block text-3/2xl lg:text-3xl font-medium',
            {
              'tracking-copy': language !== 'en-us',
              'tracking-widest': language === 'en-us',
            }
          )}
        >
          <Link to="/stories">
            <Trans>catchcopy</Trans>
          </Link>
        </p>
        <p className="md:hidden text-xl tracking-copy font-medium py-16">
          <Link to="/stories">
            <Trans>catchcopyMobile</Trans>
          </Link>
        </p>
      </div>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
